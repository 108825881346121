export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";

const FUNCTION_FLAG = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  READ: "READ",
};

const PRODUCT_FETCH_LIST_TYPE = {
  EXHIBITION: "EXHIBITION",
  NEW_ARRIVAL: "NEW_ARRIVAL",
  BEST_SELLER: "BEST_SELLER",
  RESERVATION: "RESERVATION",
  RECOMMEND: "RECOMMEND",

  AI_RECOMMEND: "AI_RECOMMEND",
};

export const DELIVERY_FREE_PRICE: number = 50000;

export const DELIVERY_FEE: number = 3000;
export const S3_SERVER_BASE_URL =
  "https://twinkleimg.s3.ap-northeast-2.amazonaws.com/";

export const DEFAULT_PAGE_SIZE = 10;

const PRODUCT_STATUS = [
  {
    value: 1,
    label: "예약",
  },
  {
    value: 2,
    label: "재고",
  },
];

const POINT_RATE = 0.03;
const PRODUCT_GLOBAL_PRICE_RATES_DEFAULT = 10;

const PRODUCT_STATUS_DEFAULT = 1;

const PRODUCT_TYPE_DEFAULT = 1;

const PRODUCT_GLOBAL_PRICE_UNIT_DEFAULT = 1;

const PRODUCT_TYPE = [
  {
    value: 1,
    label: "일반",
  },
  {
    value: 2,
    label: "가챠-구성품",
  },
  {
    value: 3,
    label: "가챠-메인",
  },
  {
    value: 4,
    label: "쿠지",
  },
  {
    value: 5,
    label: "아소토 가챠",
  },
];
const PRODUCT_GLOBAL_PRICE_UNIT = [
  {
    value: 1,
    label: "엔화",
  },
  {
    value: 2,
    label: "위안화",
  },
  {
    value: 3,
    label: "달러",
  },
  {
    value: 4,
    label: "원화",
  },
];

const PRODUCT_VISIBLITY = [
  {
    value: 1,
    label: "진열",
  },
  {
    value: 2,
    label: "진열안함",
  },
];

const PRODUCT_SALE_STATUS = [
  {
    value: 1,
    label: "판매중",
  },
  {
    value: 2,
    label: "판매중지",
  },
];

const PRODUCT_RANDOM_TYPE = [
  {
    value: 1,
    label: "무중복 랜덤 상품",
  },
  {
    value: 2,
    label: "중복 랜덤 상품",
  },

  {
    value: 3,
    label: "확정 상품",
  },
];
const PRODUCT_IS_TAX_FREE = [
  {
    value: 0,
    label: "과세",
  },
  {
    value: 1,
    label: "면세",
  },
];
const PRODUCT_OPTION_TYPE = [
  {
    value: 1,
    label: "옵션 사용 안 함",
  },
  {
    value: 2,
    label: "옵션 사용",
  },
];

const COUPON_ACTIVE = [
  {
    value: 0,
    label: "비활성",
  },
  {
    value: 1,
    label: "활성화",
  },
];

const COUPON_RANGE = [
  {
    value: 0,
    label: "전체",
  },
  {
    value: 1,
    label: "애니메",
  },
  {
    value: 2,
    label: "태그",
  },
  {
    value: 3,
    label: "카테고리",
  },
];

const BENEFIT_TYPE = [
  {
    value: 1,
    label: "쿠폰",
  },
  {
    value: 2,
    label: "포인트",
  },
];

const BENEFIT_ACTION_COUPON = [
  {
    value: 1,
    label: "발행",
  },
  {
    value: 2,
    label: "사용",
  },
  {
    value: 3,
    label: "만료",
  },
  {
    value: 4,
    label: "삭제",
  },
];

const BENEFIT_ACTION_POINT = [
  {
    value: 1,
    label: "적립",
  },
  {
    value: 2,
    label: "사용",
  },
  {
    value: 3,
    label: "만료",
  },
  {
    value: 4,
    label: "삭제",
  },
];
const FAQ = [
  {
    createTime: "",
    id: 5,
    label: "상품 관련",
    title: "사이트 상품 안내",
    content:
      "키라키라토모 사이트의 모든 상품은\n공식 국내 수입 또는 병행 수입으로 입고 되는 정품입니다.\n\n피규어류의 경우 국내 공식 판매처를 통해 수입 된 제품이며, 보다 다양한 제품군을 공급하기 위해 일부 병행 수입 제품 또한 함께 판매되고 있습니다.\n\n※ 사이트 내 모든 제품은 대상 연령 15세 이상 키덜트를 위한 제품입니다.\n※ 모든 제품은 과자 또는 식품이 포함되어 있지 않으며, 통관 절차상 단순 확인을 위해 외부 패키지가 임의로 개봉되었을 수 있습니다.\n※ 모든 제품은 소장 및 전시용으로 제작되었습니다. 용도 외 사용을 금합니다.\n",
  },
  // {
  //   createTime: "",
  //   id: 4,
  //   label: "상품 관련",
  //   title: "콜라보 제품 관련 안내",
  //   content:
  //     "키라키라토모 콜라보 제품은 수주 예약 시스템을 통해 판매되고 있습니다.\n\n" +
  //     "예약 기간 내 주문이 가능하며 예약 마감 이후에는 주문이 불가할 수 있는 점 양해부탁드립니다.\n" +
  //     "또한 콜라보 관련 상세 안내는 사이트 또는 SNS를 통해 공지드리고 있으므로,\n인스타그램/트위터의 키라키라토모 공식 계정 쪽에서 확인해주시면 감사하겠습니다.",
  // },
  {
    createTime: "",
    id: 3,
    label: "상품 관련",
    title: "피규어 A/S 관련 안내",
    content:
      "굿스마일컴퍼니, 매가하우스, 맥스팩토리, PLUM, FREEing, Aniplex 의 경우,\n굿스마일컴퍼니 또는 메가하우스 제조사 쪽으로 A/S 문의 접수가 가능합니다.\n\n" +
      `<굿스마일컴퍼니 A/S 지침서>

1) 굿스마일컴퍼니 계열사의 상품은 반드시 개봉 전에 제품 파손 및 파츠 유무를 확인하셔야 합니다.
2) 개봉 후에는 굿스마일컴퍼니 정책상 어떤 경우에도 판매처인 키라키라토모에서 AS 지원을 드릴 수 없습니다.
3) 개봉 후에는 굿스마일컴퍼니 소비자 지원센터에 직접 접수를 하셔야 AS 관련 도움을 받으실 수 있습니다.
* AS 접수 시에 불량부위가 잘 나온 사진과 구매 영수증(구매내역)을 함께 첨부하셔야 합니다.

<메가하우스 A/S 지침서>

1) 제품의 초기 불량이 의심 되시는 경우 사진과 함께 톡톡 메시지로 접수해주시면, 불량 여부를 판단하여 안내드립니다.
2) 불량이라고 판단되는 경우엔 제조사로 교환품을 요청드리며, 처리 기간은 약 15일~30일이 소요될 수 있습니다.
 *제품 공정 상 발생할 수 있는 특이점은 불량으로 보지 않습니다.
 *피규어는 수작업을 거쳐서 생산됩니다. 수작업 중 발생 할 수 있는 경미한 도색 차이 등의 이슈는 불량으로 보지 않습니다.
 *공정 중 발생하는 게이트 자국 등의 이슈는 불량으로 보지 않습니다.
 *안내 된 이미지는 견본으로 최종 양산품과 차이가 있을 수 있습니다.
 *견본과 양산품의 차이로 인한 이슈는 불량으로 보지 않습니다.
​
` +
      "크레인 경품류 또는 가챠상품(3만원 이하 제품군)은 단순 도색 미스나 마감 차이가 발생할 수 있으며, 일부 피규어의 경우 A/S가 불가능한 경우가 있습니다.\n\n자체제작 콜라보 제품의 경우 사이트 내 1:1문의를 통해 문의 접수 해주시면, 자사 내부 방침에 따라 처리를 도와드리고 있습니다." +
      "\n아래와 같은 사항이 발생할 수 있으므로 주문 전 참고 부탁드립니다.\n" +
      "- 증정용 특전은 상품에 따라 미세흠집, 마감 편차, 작은 구김/찢김 등 작은 손상이 발생할 수 있습니다.\n" +
      "- 카드류는 재질 특성 상 흠집, 구김, 미세한 인쇄 품질 차이가 발생할 수 있습니다.\n" +
      "- 패키지 박스는 상품 보호를 위한 포장재로 사용되며, 최대한 안전 포장으로 출고작업을 진행하고 있으나, 배송 상품 특성 상 흠집, 찢김, 구김 등 손상이 발생할 수 있습니다.\n" +
      "- 도금 및 플라스틱 제조 공정 상 약한 찍힘, 스크래치, 마감 편차, 미세한 점, 도금의 미세한 편차 등 미세 흠집이 발생할 수 있습니다.\n" +
      "- 피규어의 경우 일부 부분은 수작업으로 제작되어 제품별로 미세한 차이가 발생할 수 있습니다.\n",
  },
  {
    createTime: "",
    id: 2,
    label: "배송 관련",
    title: "주문 후 배송일 안내",
    content:
      "[재고상품] 의 경우,\n주문 후 평균 영업일 1-3일 내에 출고가 이루어지며, 출고 후 지역 및 택배사에 따라 배송기간이 달라질 수 있습니다. 매장에서 재고를 보유 중인 제품의 경우, 재고 상황에 따라 품절 안내를 드릴 수 있는 점 양해부탁드립니다.\n\n" +
      "[예약상품] 의 경우,\n일반적으로 발매 후 입고까지 7일 가량 소요되며, 주문서에 명시 된 상품별 출시 예정일에 따라 입고 순서대로 출고됩니다.\n출고 후 영업일 1-3일 이내 수령 가능하시며, 발매가 지연되는 경우 부득이 출고예정일은 변동될 수 있는 점 양해부탁드립니다.",
  },
  // {
  //   createTime: "",
  //   id: 1,
  //   label: "주문 관련",
  //   title: "주문 취소에 대해",
  //   content:
  //     "[재고상품] 의 경우,\n1:1문의를 통해 취소요청 남겨주시면 확인 후 바로 취소를 도와드리고 있습니다.\n다만, 발송처리가 완료된 경우에는 부득이 취소가 불가할 수 있는 점 양해부탁드립니다.\n\n" +
  //     "[예약상품] 의 경우,\n1:1문의를 통해 예약 마감일 이전에 주문을 취소요청 남겨주시면 처리를 도와드리고 있습니다.\n다만, 예약 마감일 이후에는 상품의 수주 예약이 완료된 상태이기때문에 취소가 불가할 수 있는 점 양해부탁드립니다.\n예약 상품의 반복적인 취소 요청은 내부 방침에 따라 이용이 제한될 수 있습니다.",
  // },
  // {
  //   createTime: "",
  //   id: 0,
  //   label: "주문 관련",
  //   title: "교환/반품 접수에 대해",
  //   content:
  //     "수령일로부터 7일 이내에 교환 및 반품 접수가 가능합니다.\n사이트 내 1:1문의 또는 카카오톡 채널을 통해 접수를 해주시면, 접수 후 안내에 따라 처리를 도와드리고 있습니다.\n\n단순변심의 경우, 교환/반품 시 왕복배송료 6000원이 발생하며 상품의 불량 또는 오배송으로 확인되는 경우, 교환/반품 배송비는 키라키라토모에서 부담하고 있습니다.",
  // },
];

export default {
  FAQ,
  POINT_RATE,
  COUPON_ACTIVE,
  COUPON_RANGE,

  BENEFIT_TYPE,
  BENEFIT_ACTION_COUPON,
  BENEFIT_ACTION_POINT,

  PRODUCT_FETCH_LIST_TYPE,
  DELIVERY_FREE_PRICE,
  DELIVERY_FEE,
  PRODUCT_TYPE,
  PRODUCT_STATUS,
  PRODUCT_GLOBAL_PRICE_UNIT,
  PRODUCT_STATUS_DEFAULT,
  PRODUCT_TYPE_DEFAULT,
  PRODUCT_GLOBAL_PRICE_UNIT_DEFAULT,
  PRODUCT_GLOBAL_PRICE_RATES_DEFAULT,

  PRODUCT_SALE_STATUS,
  PRODUCT_VISIBLITY,

  PRODUCT_RANDOM_TYPE,
  PRODUCT_OPTION_TYPE,
  PRODUCT_IS_TAX_FREE,
  FUNCTION_FLAG,
  S3_SERVER_BASE_URL,
};
